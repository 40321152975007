/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { getIn } from 'seamless-immutable'
import { arrayOf, bool, func, string } from 'prop-types'
import { Fade, Tab, Tabs, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'
import HdrStrongRoundedIcon from '@material-ui/icons/HdrStrongRounded'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded'
import SettingsInputSvideoRoundedIcon from '@material-ui/icons/SettingsInputSvideoRounded';
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded'
import BlurCircularRoundedIcon from '@material-ui/icons/BlurCircularRounded';
import MemoryRoundedIcon from '@material-ui/icons/MemoryRounded'
import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import OperationBadge from 'app/components/OperationBadge'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import DetailInformationContainer from './components/DetailInformation'
import DeviceComponents from './components/DeviceComponents'
import DeviceSensors from './components/DeviceSensors'
import DeviceParams from './components/DeviceParams'
import Measures from './components/MeasuresPanel'
import Alarms from './components/Alarms'
import Events from './components/Events'
import DeviceRelationsContainer from './components/HierarchyRelation'
import Installation from './components/Installation'
import Groups from './components/Groups'
import { OPERATION_COMPONENT } from 'constants/iot'
import Summary from './components/Summary'
import styles from './styles.module.scss'


const DeviceDetailInfo = ({ userRoles, loading, deviceSelected, updateBreadcrumb, fullscreen, dispatch }) => {
  const [tabsDeviceType, setTabsDeviceType] = React.useState()
  const [activeTab, setActiveTab] = React.useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    await dispatch(iotActions.cleanIotLogicalUnitRelatedToDevice())
	}, [])


  React.useEffect(() => {
    if (deviceSelected) {
      const iotTypeTabsDeviceSelected = deviceSelected?.iot_type?.data?.device_detail_tabs || [];
      setTabsDeviceType(iotTypeTabsDeviceSelected);
    }
	}, [deviceSelected])


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleChangeSelect = event => {
    setActiveTab(event.target.value);
  };

  const getIndexTab = nameTab => {
    return (tabsDeviceType && tabsDeviceType.indexOf(nameTab));
  }

  const showTab = nameTab => {
    return (tabsDeviceType && tabsDeviceType.includes(nameTab));
  }


  return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '90%', paddingBottom: 30}}>
        <div style={{ width: '80%'}}>
          <Summary deviceSelected={deviceSelected} loading={loading} />
        </div>
        <div style={{ width: '20%'}}>
          <FormControl style={{ width: '100%'}}>
              <InputLabel id="select-label">Sección</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={activeTab}
                label='Sección'
                onChange={handleChangeSelect}
              >
                {tabsDeviceType?.map((tab, index) => (
                  <MenuItem value={index}>{tab}</MenuItem>
                ))}
              </Select>
          </FormControl>
        </div>
      </div>
      <div>
        <Tabs
            value={activeTab}
            className={styles.tabs}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabsDeviceType && tabsDeviceType.includes('DETALLE') && (
              <Tab
              style={{ minWidth: '140px' }}
              className={styles.tab}
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ListRoundedIcon
                    style={{ fontSize: 25, marginRight: 10 }}
                  ></ListRoundedIcon>
                  <span>Detalle</span>
                </div>
              }
            />
            )}
            {tabsDeviceType && tabsDeviceType.includes('UBICACION') && (
              <Tab
                style={{ minWidth: '140px' }}
                className={styles.tab}
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <GpsFixedRoundedIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></GpsFixedRoundedIcon>
                    <span>Ubicación</span>
                  </div>
                }
              />
            )}
            {tabsDeviceType && tabsDeviceType.includes('COMPONENTES') && (
              <Tab
                style={{ minWidth: '140px' }}
                className={styles.tab}
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MemoryRoundedIcon
                      style={{ fontSize: 25, marginRight: 10 }}
                    ></MemoryRoundedIcon>
                    <span>Componentes</span>
                  </div>
                }
              />
            )}
            {tabsDeviceType && tabsDeviceType.includes('SENSORES') && (
              <Tab
                style={{ minWidth: '140px' }}
                className={styles.tab}
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SettingsInputSvideoRoundedIcon
                      style={{ fontSize: 25, marginRight: 10 }}
                    ></SettingsInputSvideoRoundedIcon>
                    <span>Sensores</span>
                  </div>
                }
              />
            )}
            {tabsDeviceType && tabsDeviceType.includes('PARAMETROS') && (
            <Tab
              style={{ minWidth: '140px' }}
              className={styles.tab}
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ListRoundedIcon
                    style={{ fontSize: 25, marginRight: 10 }}
                  ></ListRoundedIcon>
                  <span>Parametros</span>
                </div>
              }
            />
            )}
            {tabsDeviceType && tabsDeviceType.includes('MEDICIONES') && (
              <Tab
                style={{ minWidth: '140px' }}
                className={styles.tab}
                disabled={
                  deviceSelected?.iot_type.name === OPERATION_COMPONENT.TELEC_PS_CON
                }
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SpeedRoundedIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></SpeedRoundedIcon>
                    <span>Mediciones</span>
                  </div>
                }
              />
            )}
            {tabsDeviceType && tabsDeviceType.includes('EVENTOS') && (
              <Tab
                style={{ minWidth: '140px' }}
                className={styles.tab}
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <PlaylistAddCheckRoundedIcon
                      style={{ fontSize: 25, marginRight: 10 }}
                    ></PlaylistAddCheckRoundedIcon>
                    <span>Eventos</span>
                  </div>
                }
              />
            )}
            {tabsDeviceType && tabsDeviceType.includes('ALARMAS') && (
            <Tab
              style={{ minWidth: '140px' }}
              className={styles.tab}
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {deviceSelected && deviceSelected?.status_operation !== 'G' ? (
                    <Fade
                      in={
                        deviceSelected && deviceSelected?.status_operation !== 'G'
                      }
                    >
                      <OperationBadge
                        type={deviceSelected?.status_operation}
                        style={{ marginRight: 10 }}
                      ></OperationBadge>
                    </Fade>
                  ) : (
                    <ErrorOutlineRoundedIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></ErrorOutlineRoundedIcon>
                  )}
                  <span>Alarmas</span>
                </div>
              }
            />
            )}
            {/* <Tab label="IsAlive" /> */}
            {tabsDeviceType && tabsDeviceType.includes('DISP.RELACIONADOS') && (
              <Tab
                style={{ minWidth: '140px' }}
                className={styles.tab}
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <HdrStrongRoundedIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></HdrStrongRoundedIcon>
                    <span>Disp. relacionados</span>
                  </div>
                }
              />
            )}
            {tabsDeviceType && tabsDeviceType.includes('GRUPOS') && (
              <Tab
                style={{ minWidth: '140px' }}
                className={styles.tab}
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <BlurCircularRoundedIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></BlurCircularRoundedIcon>
                    <span>Grupos</span>
                  </div>
                }
              />
            )}
        </Tabs>
      </div>
      <div className={styles.tabContent}>
        {activeTab === getIndexTab('DETALLE') && showTab('DETALLE') && (
          <DetailInformationContainer></DetailInformationContainer>
        )}
        {activeTab === getIndexTab('UBICACION') && showTab('UBICACION') && <Installation></Installation>}
        {activeTab === getIndexTab('COMPONENTES') && showTab('COMPONENTES') && <DeviceComponents fullscreen={fullscreen} userRoles={userRoles}></DeviceComponents>}
        {activeTab === getIndexTab('SENSORES') && showTab('SENSORES') && <DeviceSensors fullscreen={fullscreen} userRoles={userRoles}></DeviceSensors>}
        {activeTab === getIndexTab('PARAMETROS') && showTab('PARAMETROS') && <DeviceParams fullscreen={fullscreen} userRoles={userRoles}></DeviceParams>}
        {activeTab === getIndexTab('MEDICIONES') && showTab('MEDICIONES') && <Measures fullscreen={fullscreen}></Measures>}
        {activeTab === getIndexTab('EVENTOS') && showTab('EVENTOS') && <Events></Events>}
        {activeTab === getIndexTab('ALARMAS') && showTab('ALARMAS') && <Alarms></Alarms>}
        {activeTab === getIndexTab('DISP.RELACIONADOS') && showTab('DISP.RELACIONADOS') && (
          <DeviceRelationsContainer></DeviceRelationsContainer>
        )}
        {activeTab === getIndexTab('GRUPOS') && showTab('GRUPOS') && <Groups userRoles={userRoles}></Groups>}
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  userRoles: store.auth.userRoles,
  deviceSelected: getIn(store, ['iot', 'iotDeviceDetail']),
  loading: getIn(store, ['iot', 'iotDeviceDetailLoading']),
})

DeviceDetailInfo.propTypes = {
  loading: bool,
  userRoles: arrayOf(string),
  updateBreadcrumb: func,
}

export default connect(mapStateToProps)(DeviceDetailInfo)
