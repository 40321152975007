import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { memo, useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { any, shape } from 'prop-types'

import { Card, Typography, FormControl, Select, MenuItem, Button } from '@material-ui/core'
import FormatAlignJustifyRoundedIcon from '@material-ui/icons/FormatAlignJustifyRounded'
import EventRoundedIcon from '@material-ui/icons/EventRounded'
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded'
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import WTDateRangePicker from 'app/components/WTDateRangePicker'
import { actionCreators as iotActions } from 'redux/iotHandlers'

import Alarms from './components/Alarms'
import AlarmsCancel from './components/AlarmsCancel'
import Charts from './components/Charts'
import DataExportComponent from './components/DataExportModal/index'
import styles from './styles.module.scss'

const LEVEL_GRAPHICS = 'DEVICE_DETAIL_ALARMS';

const AlarmsInformationContainer = ({
  dispatch,
  deviceSelected,
  iotTypeGraphics,
  iotTypeGraphicsLoading
}) => {
  const [filterValues, setFilterValues] = useState([])
  const [panelType, setpanelType] = useState('chart')
  const [iotTypeGraphicSelected, setIotTypeGraphicSelected] = useState(null)
  const [timeRange, settimeRange] = useState('24h')
  const [isLoading, setIsLoading] = useState(true)
  const [reset, setReset] = useState(false)
  const [customDateLabel, setCustomDateLabel] = useState('Otro período')
  const [customDateFilter] = useState({ startDate: null, endDate: null })
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [dataExportDialogOpened, setDataExportDialogOpened] = useState(false)

  const customDateButtonRef = useRef(null)
  const TIMESTAMP = 'executedAt'
  
  const [t] = useTranslation('devices')

  const handleChangeGroup = event => {
    let aux = iotTypeGraphics.filter(graphic => graphic.id === event.target.value)
    setIotTypeGraphicSelected(aux[0] ? aux[0] : null)
    setReset(true) //reset Chart
  }

  const formatDate = d =>
    d.toLocaleDateString('es-ES', { type: 'medium', year: 'numeric', month: 'short', day: 'numeric' }).replace('.', '').replace('sept', 'sep')

  const handleCustomDateChange = dateRange => {
    if (dateRange) {
      setReset(true) //reset Graphics
      dispatch(iotActions.cleanDeviceAlarms()) //disparar
      dispatch(iotActions.cleanDeviceAlarmsCancel()) //disparar
      let newDateRangeLabel = formatDate(dateRange.startDate) + ' - ' + formatDate(dateRange.endDate)
      setCustomDateLabel(newDateRangeLabel)
      updateDateTimeFilter(dateRange)
    }
  }

  const updateDateTimeFilter = dateRange => {
    let dateFilters = []
    if (dateRange) {
      dateFilters = [
        { field: TIMESTAMP, operator: 'gt', value: dateRange.startDate },
        { field: TIMESTAMP, operator: 'lt', value: dateRange.endDate },
      ]
      dateFilters[0].value.toJSON = function () {
        return moment(this).format()
      } //preservar zona horaria
      dateFilters[1].value.toJSON = function () {
        return moment(this).format()
      } //preservar zona horaria
    }
    setFilterValues(prevFilters => [...prevFilters.filter(filter => filter.field !== TIMESTAMP), ...dateFilters])
  }

  const handleCloseDatePicker = () => {
    setDatePickerOpen(false)
  }

  const handleTimeBarSelection = (event, newSelection) => {
    if (newSelection) {
      setReset(true)
      settimeRange(newSelection)
      if (newSelection === 'custom') {
        setDatePickerOpen(true)
      } else {
        setDatePickerOpen(false)
        calcTimeFilter(newSelection)
      }
    } else {
      if (timeRange === 'custom' && !datePickerOpen) {
        setDatePickerOpen(true)
      }
    }
  }

  const handlePanelSelection = (event, newSelection) => {
    if (newSelection) {
      dispatch(iotActions.cleanDeviceAlarms()) //disparar
      dispatch(iotActions.cleanDeviceAlarmsCancel()) //disparar
      setpanelType(newSelection)
    }
  }

  const calcTimeFilter = period => {
    let start
    switch (period) {
      case '24h':
        start = moment().subtract(24, 'hours').toDate()
        updateDateTimeFilter({ startDate: start, endDate: moment().toDate() })
        break
      case '7d':
        start = moment().subtract(7, 'days').toDate()
        updateDateTimeFilter({ startDate: start, endDate: moment().toDate() })
        break
      case '30d':
        start = moment().subtract(30, 'days').toDate()
        updateDateTimeFilter({ startDate: start, endDate: moment().toDate() })
        break

      default:
        break
    }
  }

  useEffect(() => {
    calcTimeFilter(timeRange)

    const graphicsLevelFilter = [
      { field: 'graph_level', operator: 'eq', value: LEVEL_GRAPHICS}
    ];
    dispatch(iotActions.getIotTypeGraphics(deviceSelected.type_id, LEVEL_GRAPHICS, graphicsLevelFilter));
  }, [])

  useEffect(() => {
    if (filterValues && filterValues.length > 0) {
      dispatch(iotActions.cleanDeviceAlarms()) //disparar
      dispatch(iotActions.cleanDeviceAlarmsCancel()) //disparar
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues])

  useEffect(() => {
    if (iotTypeGraphics && iotTypeGraphics.length > 0) {
      setIotTypeGraphicSelected(iotTypeGraphics[0])
      setIsLoading(false)
    }
  }, [iotTypeGraphics, iotTypeGraphicsLoading])

  const handleExportarDatos = () => {
    setDataExportDialogOpened(true)
  }

  const handleCloseDataExportDialog = () => {
    setDataExportDialogOpened(false)
  }

  return (
    <>
      <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
        <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ToggleButtonGroup size='small' value={panelType} exclusive onChange={handlePanelSelection} style={{ marginRight: 20 }}>
                <ToggleButton value='datalistCancelAlarms'>
                  <FormatAlignJustifyRoundedIcon fontSize='small'></FormatAlignJustifyRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    cancel. Alarm.
                  </Typography>
                </ToggleButton>

                <ToggleButton value='datalistAlarms'>
                  <FormatAlignJustifyRoundedIcon fontSize='small'></FormatAlignJustifyRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    Alarmas
                  </Typography>
                </ToggleButton>

                <ToggleButton value='chart'>
                  <TimelineRoundedIcon fontSize='small'></TimelineRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    Gráficos
                  </Typography>
                </ToggleButton>

              </ToggleButtonGroup>
              {panelType === 'chart' && iotTypeGraphicSelected !== null && !isLoading && (
                <FormControl className={styles.selectField}>
                  <Select id='select-outlined' variant='outlined' value={iotTypeGraphicSelected?.id} onChange={handleChangeGroup}>
                    {iotTypeGraphics && iotTypeGraphics.map(graphics => <MenuItem value={graphics.id}>{`${graphics.title}`}</MenuItem>)}
                  </Select>
                </FormControl>
              )}
            </div>

            <div style={{ display: 'flex', gap: 20 }}>
              <ToggleButtonGroup size='small' value={timeRange} exclusive onChange={handleTimeBarSelection}>
                <ToggleButton value='24h'>
                  <Typography variant='body'>24 h.</Typography>
                </ToggleButton>
                <ToggleButton value='7d'>
                  <Typography variant='body'>7 días</Typography>
                </ToggleButton>
                <ToggleButton value='30d'>
                  <Typography variant='body'>30 días</Typography>
                </ToggleButton>
                <ToggleButton value='custom' ref={customDateButtonRef}>
                  <EventRoundedIcon fontSize='small'></EventRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    {customDateLabel}
                  </Typography>

                  <WTDateRangePicker
                    onChange={handleCustomDateChange}
                    dates={customDateFilter}
                    open={datePickerOpen}
                    anchorEl={customDateButtonRef?.current}
                    customAnchor={true}
                    onClose={handleCloseDatePicker}
                  ></WTDateRangePicker>
                </ToggleButton>
              </ToggleButtonGroup>
              <Button variant='outlined' startIcon={<DescriptionRoundedIcon />} onClick={handleExportarDatos}>
                {t('deviceDataExportTitle')}
              </Button>
            </div>
          </div>
        </Card>
        {panelType === 'datalistCancelAlarms' && <AlarmsCancel></AlarmsCancel>}
        {panelType === 'datalistAlarms' && <Alarms></Alarms>}
        {panelType === 'chart' && iotTypeGraphicSelected && (
          <Charts
            id={deviceSelected.id}
            typeId={iotTypeGraphicSelected?.id}
            chartName={iotTypeGraphicSelected?.name}
            startDate={filterValues[0]?.value}
            endDate={filterValues[1]?.value}
          />
        )}
      </div>
      <DataExportComponent open={dataExportDialogOpened} handleClose={handleCloseDataExportDialog}></DataExportComponent>
    </>
  )
}

const mapStateToProps = store => ({
  deviceSelected: store.iot.iotDeviceDetail,
  iotTypeGraphics: store.iot.iotTypeGraphics[LEVEL_GRAPHICS],
  iotTypeGraphicsLoading: store.iot.iotTypeGraphicsLoading,
})

AlarmsInformationContainer.propTypes = {
  deviceSelected: shape(any)
}

export default connect(mapStateToProps)(memo(AlarmsInformationContainer))
